.react-slideshow-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.react-slideshow-fade-wrapper .images-wrap div img {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
}

.overlay {
  background: #000;
  opacity: 0.8;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.container {
  color: #fff;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  word-spacing: -1px;
}

.text {
  width: 50%;
}

p {
  font-size: 1.5rem;
  font-family: 'Righteous';
}

.name {
  font-family: 'Cookie';
  font-size: 2.5rem;
  color: #79d3ea;
  text-align: center;
}

.nav {
  display: none;
}

.icons {
  text-decoration: none;
}

a {
  color: inherit;
}

.github {
  color: grey;
}

.github:hover {
  background: grey;
  color: #fff;
}


.twitter {
  color: #1da1f2;
}

.twitter:hover {
  background: #1da1f2;
  color: #fff;
}

.instagram {
  color: #e1306c;
}

.instagram:hover {
  background-color: #e1306c;
  color: #fff;
}

.facebook {
  color: #3b5998;
}

.facebook:hover {
  background-color: #3b5998;
  color: #fff;
}

.linkedin {
  color: #0077b5;
}

.linkedin:hover {
  background-color: #0077b5;
  color: #fff;
}

.envelope {
  color: #D44638;
}

.envelope:hover {
  background-color: #D44638;
  color: #fff;
}

.div-icon {
  background-color: white;
  padding: 10px;
  border-radius: 15px;
  width: 25px;
  display: flex;
  justify-content: center;
}

.contact-icons {
  display: flex;
  justify-content: space-between;
  font-size: 1.7rem;
  margin-top: 15%;
}

span.Cursor.Cursor--blinking {
  display: none;
}

@media(max-width: 420px) {
  .name {
    font-size: 1.2rem;
  }

  p {
    font-size: 1rem;
  }

  .contact-icons {
    font-size: inherit;
  }

  .div-icon {
    width: 10px;
  }

  .text {
    width: 80%;
  }

  .container {
    height: 80vh;
  }

  .react-slideshow-fade-wrapper .images-wrap div img {
    width: 150%;
    position: fixed;
    top: 0;
    left: 0;
    background-size: cover;
    height: 100vh;
  }

  .contact-icons {
    margin-top: 20%;
  }
}

@media(max-width: 400px) {
  .container {
    height: 95vh;
  }

  .name {
    font-size: 1.25rem;
  }

  p {
    font-size: 0.8rem;
  }
}

@media(width: 375px) {
  .container {
    height: 90vh;
  }
}

@media(max-height: 823px) {
  .container {
    height: 95vh;
  }

  .contact-icons {
    margin-top: 25%;
  }
}

@media(min-width: 420px) and (max-width: 750px){
  .name {
    font-size: 2rem;
  }

  .text {
    width: 70%;
  }

  p {
    font-size: 1.25rem;
  }

  .container {
    height: 57vh;
  }


  .react-slideshow-fade-wrapper .images-wrap div img {
    width: 120% !important;
  }
}

@media(min-width: 750px) and (max-width: 1024px){
  .text {
    width: 75%;
  }

  .react-slideshow-fade-wrapper .images-wrap div img {
    height: 100vh;
  }
}

@media(width: 1024px) {
  .container {
    height: 80vh;
  }
}
